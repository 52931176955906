import React, { useState, useEffect } from "react";
import Content from "../../sections/careers/Content"
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "../../utils/helperFn";
import { Container, Row, Col } from "react-bootstrap";
import { Section } from "../../components/Core";
import Seo from "../../components/Seo/Seo";
import { Button } from "../../../src/components/Core";
import HeroCareers from "../../components/Rebrand/Hero/HeroCareers";
import group_image from './../../assets/image/rebrand/jpg/group_image.jpg';
import ryan from './../../assets/image/rebrand/jpg/ryan.jpg';
import { navigate } from "gatsby";
import FreshStreamWidget from "./FreshStreamWidget";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import FeatureCard from "../../sections/FeatureGrid/shared/FeatureCard";
import { GetFeatureGrids } from "../../utils/helperFn";
import YouFibreIcons from "../../components/YouFibreIcons/YouFibreIcons";

const Careers = () => {
    const [pageData, setPageData] = useState(null);
    const [youtubeUrl, setYoutubeUrl] = useState(null);
    const [careersData, setCareersData] = useState(null);
    const [benefitsData, setBenefitsData] = useState(null);

    const responsive = {
        0: { items: 1 },
        568: { items: 1 },
        768: { items: 2 },
        1024: { items: 3 },
    };

    const dataQuery = useStaticQuery(graphql`
        query CareersSectionQuery {
            pageData: strapiCareersSection {
                section_title
                section_subtitle
                section_text_1
                job_section_title
                job_section_jobs_available
                job_section_jobs_unavailable
                section_2_title
                section_2_text
                section_3_title
                section_4_title
                section_4_text
                youtube
                autoplay
                quote_text
                quote_name
                quote_position
            }
            careers: allStrapiFeatureGrids(filter: {page: {eq: "careers"}}) {
                edges {
                    node {
                        id
                        title
                        page
                        grid_features {
                            icon
                            description
                            title
                        }
                    }
                }
            }
            benefits: allStrapiFeatureGrids(filter: {page: {eq: "benefits"}}) {
                edges {
                    node {
                        id
                        title
                        page
                        grid_features {
                            icon
                            description
                            title
                        }
                    }
                }
            }
        }
    `);

    useEffect(() => {
        setPageData(getProperty(dataQuery, 'pageData'));
        setCareersData(getProperty(dataQuery,'careers')); 
        setBenefitsData(getProperty(dataQuery,'benefits')); 
    }, []);

    useEffect(() => {
        pageData && console.log("pageData ====> ", pageData);
        setYoutubeUrl(`https://www.youtube.com/embed/${pageData?.youtube}?autoplay=${pageData?.autoplay}&amp;playsinline=1&amp;start=1&amp;enablejsapi=1&amp;controls=0&amp;fs=0&amp;loop=1&amp;rel=0&amp;showinfo=0&amp;disablekb=1&amp;playlist=${pageData?.youtube}&amp;widgetid=1`);
    }, [pageData]);

    useEffect(() => {
        console.log("careersData ====> ", careersData?.edges[0]?.node);
    }, [careersData]);

    useEffect(() => {
        console.log("benefitsData ====> ", benefitsData?.edges[0]?.node);
    }, [benefitsData]);

    return (
        <div className="no-padding-wrapper">
            <Seo page="careers" />
            <HeroCareers />
            <div className="careers pt-5 ">
                <Container>
                    <Row>
                        <Col md={12} lg={6}>
                            <h2 className="mb-5">{pageData?.section_subtitle}</h2>
                            <p>{pageData?.section_text_1}</p>
                            <Button onClick={() => navigate('/careers/#jobs')} className="d-none d-lg-block green-bg medium-button mt-5 mb-5">View jobs</Button>
                            <div className="d-block d-lg-none text-center">
                                <Button onClick={() => navigate('/careers/#jobs')} className="green-bg medium-button mt-5 mb-5">View jobs</Button>
                            </div>
                        </Col>
                        <Col md={12} lg={6}>
                            <img src={group_image} className="w-100 removeHorizontalPadding" />
                        </Col>
                        <Col md={12} className="text-center pt-5">
                            <h2>{pageData?.section_2_title}</h2>
                            <p className="pl-5 pr-5">{pageData?.section_2_text}</p>
                        </Col>
                        <Col md={12} className="text-center pt-5 pb-sm-0 pb-md-5">
                            <iframe className="ytPlayer removeHorizontalPadding" frameborder="0" allowfullscreen="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="YouFibre" src={youtubeUrl}></iframe>
                        </Col>
                    </Row>
                </Container>
            </div>
            { careersData && <div className="text-center dark-bg pt-5 pb-5">
                <Container>
                    <Row>
                        <Col md={12} className="pb-5">
                            <h2>{pageData?.section_3_title}</h2>
                        </Col>
                        <Col xs={12} className="company-values center-items">
                            <AliceCarousel
                                mouseTracking
                                responsive={responsive}
                                disableButtonsControls={true}
                                infinite={true}
                                controlsStrategy="alternate">
                                    { careersData?.edges[0]?.node.grid_features?.map((item, index) => 
                                        <>
                                            <div  className="center-items pb-3">
                                                <h4 className="big-circle green-bg">{index+1}</h4>
                                                <h4 dangerouslySetInnerHTML={{ __html: item.title }}></h4>
                                                <p className="px-3">{item.description}</p>
                                            </div>
                                        </>
                                    )}
                                </AliceCarousel>
                        </Col>
                    </Row>
                </Container>
            </div>
            }
            <div className="testimonial">
                <Row>
                    <Col xs={12} sm={6} md={5} lg={4} className="image grey-bg">
                        <img src={ryan} className="w-100"/>
                    </Col>
                    <Col xs={12} sm={6} md={7} lg={8} className="center-items">
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={8} className="p-5">
                                <div className="quote">
                                    <p>{pageData?.quote_text}</p>
                                    <p><b>{pageData?.quote_name}</b><br /><small>{pageData?.quote_position}</small></p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            { benefitsData && <div className="text-center green-bg pt-5 pb-5">
                <Container>
                    <Row>
                        <Col md={12} className="pb-5">
                            <h2>{pageData?.section_4_title}</h2>
                            <p>{pageData?.section_4_text}</p>
                        </Col>
                        <Col xs={12} className="center-items">
                            <AliceCarousel
                                mouseTracking
                                responsive={responsive}
                                disableButtonsControls={true}
                                infinite={true}
                                autoPlay={true}
                                autoPlayDirection={'ltr'}
                                autoPlayStrategy={'all'}
                                autoPlayInterval={4000}
                                infinite={true}
                                controlsStrategy="alternate">
                                    {benefitsData?.edges[0]?.node.grid_features?.map((item, index) => 
                                        <>
                                        <div className="align-top pb-3">
                                            <img src={YouFibreIcons(item.icon)} className="big-icon" alt="YouFibre" />
                                            <h4>{item.title}</h4>
                                            <p>{item.description}</p>
                                        </div>
                                        </>
                                    )}
                                </AliceCarousel>
                        </Col>
                    </Row>
                </Container>
            </div>
            }
            <Section>
                <Container id="jobs">
                    <Row>
                        <Col md={12} className="text-center pt-3">
                            <h2>{pageData?.job_section_title}</h2>
                            <FreshStreamWidget />
                        </Col>
                    </Row>
                </Container>
            </Section>
        </div>
    )
}
export default Careers